import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import style from "./Nav.module.css";


const Nav: React.FC = () => {
  const location = useLocation();
  
  return (
    <nav className={style.container}>
      <NavLink 
        to="/" 
        end 
        className={
          location.pathname === '/'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="home page"
      >
        Головна
      </NavLink>
      <NavLink to="/search" className={
          location.pathname === '/search'
            ? [style.link, style.active].join(' ')
            : style.link
        }>
        Пошук
      </NavLink>
      {/* <NavLink 
        to="/hot-tours" 
        className={
          location.pathname === '/hot-tours'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="hot tours page"
      >
        Гарячі тури
      </NavLink> */}
      <NavLink 
        to="/tickets" 
        className={
          location.pathname === '/tickets'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="tickets page"
      >
        Пошук авіаквитків, готелів
      </NavLink>
      <NavLink 
        to="/news" 
        className={
          location.pathname === '/news'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="news page"
      >
        Корисна інформація
      </NavLink>
      <NavLink 
        to="/opinions" 
        className={
          location.pathname === '/opinions'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="opinions page"
      >
        Відгуки
      </NavLink>
      <NavLink 
        to="/about-us" 
        className={
          location.pathname === '/about-us'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="about us page"
      >
        Про нас
      </NavLink>
      {/* {user.isAdmin && <NavLink to="/admin" className={
          location.pathname === '/admin'
            ? [style.link, style.active].join(' ')
            : style.link
        }>
      Адмін
      </NavLink>}
      {user && <NavLink to="/user" className={
          location.pathname === '/user'
            ? [style.link, style.active].join(' ')
            : style.link
        }>
      Юзер
      </NavLink>} */}
    </nav>
  );
};

export default Nav;