import { Route, Routes, Navigate} from "react-router-dom";
import  { useEffect, lazy } from "react";
import { ToastContainer } from "react-toastify";

import { useAppDispatch } from "../../redux/hooks";
import { current } from "../../redux/auth/operations";
import useAuth from "../../hooks/useAuth";
import { PrivateRoutes } from "../PrivateRoute";
import Spinner from "../Spinner/Spinner";
import Layout from "../SharedLayout/SharedLayout";
import NotFoundPage from "../../pages/NotFoundPage";
import UserPage from "../../pages/UserPage";
import AdminPage from "../../pages/AdminPage";

import 'react-toastify/dist/ReactToastify.css';


const Home = lazy(() => import("../../pages/HomePage"));
// const HotTours = lazy(() => import("../../pages/HotToursPage"));
const SearchTourPage = lazy(() => import("../../pages/SearchTourPage"));
const TicketsPage = lazy(() => import("../../pages/TicketsPage"));
const AboutUsPage = lazy(() => import("../../pages/AboutUsPage"));
const OpinionsPage = lazy(() => import("../../pages/OpinionsPage"));
const NewsPage = lazy(() => import("../../pages/NewsPage"));
const RegisterPage = lazy(() => import("../../pages/RegisterPage"));
const LoginPage = lazy(() => import("../../pages/LoginPage"));
const PasswordPage = lazy(() => import("../../pages/PasswordPage"));


const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(current());
  }, [dispatch]);

  const {isRefreshing, user } = useAuth();
  // console.log('isRefreshing', isRefreshing)

  return (
    <>
      {isRefreshing ? (
        <Spinner />
      ) : (

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="search" element={<SearchTourPage />} />
          {/* <Route path="hot-tours" element={<HotTours />} /> */}
          <Route path="tickets" element={<TicketsPage />} />
          <Route path="opinions" element={<OpinionsPage />} />
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="news" element={<NewsPage />} />
          <Route path="register" element={<RegisterPage />}/>
          <Route path="login" element={<LoginPage />}/>
          <Route path="/reset/:token/:email" element={<PasswordPage />}/>
          <Route path="*" element={<NotFoundPage />} />
        <Route
            path='user'
            element={<PrivateRoutes component={UserPage}/> }
          /> 
          <Route
              path='user'
              element={<PrivateRoutes component={UserPage}/> }
            /> 
            <Route
              path='admin'
              element={user.isAdmin ? <AdminPage/> : <Navigate to="/login"/>}
            /> 
          </Route>
        </Routes>
     
      )}
      <ToastContainer
        //  position="top-right"
        // autoClose={5000}
        // hideProgressBar={false}
        // newestOnTop={false}
        // closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
        // theme="colored"
      />
    </>
  );
};

export default App;
